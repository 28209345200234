import { BubbleTerm } from "Everlaw/UI/BubbleTerm";
import * as Icon from "Everlaw/UI/Icon";

/*
This file was split out from Everlaw/Type/Type so that its strict TS errors could be fixed. This
file is the dependency for a few other commonly used modules, so it was important to fix this first
to make other core modules compliant with strict TS rules.
 */

export enum AddressTermKind {
    EMAIL = "EMAIL",
    NAME = "NAME",
    DOMAIN = "DOMAIN",
    TEXT = "TEXT",
    NO_VALUE = "NO_VALUE",
}

export interface AddressTermJson {
    kind: AddressTermKind;
    value: string;
    associatedPrivateEmails?: string[];
    associatedSharedEmails?: string[];
    associatedNames?: string[];
}

export class AddressTerm implements BubbleTerm {
    static TERM_KIND_TO_ICON: Record<AddressTermKind, { class: string; alt: string } | undefined> =
        {
            NAME: { class: "user-light-20", alt: "contact name" },
            EMAIL: { class: "mail-light-20", alt: "email address" },
            DOMAIN: { class: "at-light-20", alt: "domain" },
            TEXT: undefined,
            NO_VALUE: undefined,
        };

    value: string;
    kind: AddressTermKind;
    associatedPrivateEmails: string[];
    associatedSharedEmails: string[];
    associatedNames: string[];

    static fromJson(obj: AddressTermJson): AddressTerm {
        return new AddressTerm(
            obj.value,
            obj.kind,
            obj.associatedPrivateEmails,
            obj.associatedSharedEmails,
            obj.associatedNames,
        );
    }

    constructor(
        value: string,
        kind?: AddressTermKind,
        associatedPrivateEmails?: string[],
        associatedSharedEmails?: string[],
        associatedNames?: string[],
    ) {
        this.value = value;
        this.kind = kind || AddressTermKind.TEXT;
        this.associatedPrivateEmails = associatedPrivateEmails || [];
        this.associatedSharedEmails = associatedSharedEmails || [];
        this.associatedNames = associatedNames || [];
    }

    icon() {
        const icon = AddressTerm.TERM_KIND_TO_ICON[this.kind];
        return icon && new Icon(`${icon.class} term-kind-icon`, { alt: icon.alt });
    }

    /**
     * Up to two rows of associated emails which will appear as "subscript" rows after the name term
     * they are associated with.
     */
    associatedRows(): string[] {
        const emails = [...this.associatedPrivateEmails, ...this.associatedSharedEmails];
        if (!emails) {
            return [];
        }

        const emailAppendedTo = (row: string, i: number) =>
            row + emails[i] + (i < emails.length - 1 ? ", " : "");

        const result = [];
        let emailIdx = 0;
        for (let rowIdx = 0; rowIdx < 2; rowIdx++) {
            if (emailIdx === emails.length) {
                break;
            }

            // 2nd row slightly shorter to allow for potential " ..." if more emails than fit
            // in two rows.
            const maxRowLen = rowIdx === 0 ? 80 : 75;

            let row = "";

            // at least one email in each row, add as many as still fit
            do {
                row = emailAppendedTo(row, emailIdx++);
            } while (emailIdx < emails.length && emailAppendedTo(row, emailIdx).length < maxRowLen);

            // second (last) row - and yet there are more emails, end with "..."
            if (rowIdx === 1 && emailIdx < emails.length) {
                row += " ...";
            }
            result.push(row);
        }
        return result;
    }

    toString() {
        return this.value;
    }

    displayValue() {
        return this.value;
    }

    /**
     * We don't have exact or even close to exact hit count for name terms, since they aggregate
     * the results of all the emails.
     * However if the top autocomplete results include any of the associated emails we sum those up
     * and choose the higher of this and the name term count (which is the count of appearances
     * of the name itself.)
     * But that's just a lower bound (More or less. Could be cases where it's wrong, if multiple
     * emails of the same name appear in a single doc. But I think we can live with that slight
     * possibility.)
     */
    hitsIsLowerBound() {
        return this.kind === AddressTermKind.NAME;
    }

    /**
     * Hide the associated emails field so it is not part of the EQL. The server finds the (most
     * up to date) associated emails when it searches for a name term, so it's not needed in the
     * EQL.
     */
    toEqlValue() {
        return this.kind === AddressTermKind.NAME ? new AddressTerm(this.value, this.kind) : this;
    }
}
